import React from "react";
import {fetchPostsAndUsers} from "actions/blog";
import {connect} from "react-redux";
import {Store} from "types/store";
import {Post} from "types/post";
import UserHeader from "blog/UserHeader";

type Props = { fetchPostsAndUsers: () => {}, posts: Post[] }
type State = {}

class PostList extends React.Component<Props, State> {
    state: State = {};

    componentDidMount = () => {
        this.props.fetchPostsAndUsers();
    }

    renderList = () => {
        return this.props.posts.map((post: Post) => {
            return (
                <div className={"item"} key={post.id}>
                    <i className={"large middle aligned icon user"}/>
                    <div className={"content"}>
                        <div className={"description"}>
                            <h2>{post.title}</h2>
                            <p>{post.body}</p>
                        </div>
                        <UserHeader userId={post.userId}/>
                    </div>
                </div>
            );
        })
    }

    render = () => {
        const {} = this.props;
        const {} = this.state;

        //console.log(this.props.posts);

        return (
            <div className={"ui relaxed divided list"}>
                {this.renderList()}
            </div>
        );
    }
}

const mapStateToProps = (state: Store) => {
    return {posts: state.posts};
}

export default connect(mapStateToProps, {
    fetchPostsAndUsers
})(PostList)