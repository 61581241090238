import React from "react";
import {InputForm} from "input-form/InputForm";
import {Seasons} from "seasons/Seasons";
import {ImageApp} from "image/ImageApp";
import {CommentCard} from "comment-card/CommentCard";
import {VideoApp} from "videos/VideoApp";
import {WidgetApp} from "widget/WidgetApp";
import {Route} from "navigation/Route";
import {Header} from "navigation/Header";
import {SongApp} from "songs/SongApp";
import PostList from "blog/PostList";

type Props = {}
type State = {}

export class App extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div className={"ui container divided items"}>
                <Header/>
                <Route path={"/"}>
                    <Seasons/>
                </Route>
                <Route path={"/comment"}>
                    <CommentCard/>
                </Route>
                <Route path={"/images"}>
                    <ImageApp/>
                </Route>
                <Route path={"/video"}>
                    <VideoApp/>
                </Route>
                <WidgetApp/>
                <Route path={"/songs"}>
                    <SongApp/>
                </Route>
                <Route path={"/blog"}>
                    <PostList/>
                </Route>
            </div>
        );
    }
}