import React from "react";
import SongsList from "songs/SongsList";
import SongDetail from "songs/SongDetail";

type Props = {}
type State = {}

export class SongApp extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div className={"ui container grid"}>
                <div className={"ui row"}>
                    <div className={"column eight wide"}>
                        <SongsList/>
                    </div>
                    <div className={"column eight wide"}>
                        <SongDetail/>
                    </div>
                </div>
            </div>
        );
    }
}