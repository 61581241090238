import jsonPlaceHolder from "api/jsonPlaceHolder";
import _ from "lodash";

export const fetchPostsAndUsers = () => async (dispatch: any, getState: any) => {
    await dispatch(fetchPosts());
    //console.log(getState().posts);
    // const userIds=_.uniq(_.map(getState().posts, 'userId'));
    // //console.log(userIds);
    // userIds.forEach(id=>dispatch(fetchUser(id)));

    _.chain(getState().posts)
        .map('userId')
        .uniq()
        .forEach(id => dispatch(fetchUser(id)))
        .value()

}

export const fetchPosts = () => async (dispatch: any) => {
    const response = await jsonPlaceHolder.get('/posts');
    dispatch({type: 'FETCH_POSTS', payload: response.data})
};


export const fetchUser = (id: number) => async (dispatch: any) => {
    const response = await jsonPlaceHolder.get('/users/' + id);
    dispatch({type: 'FETCH_USER', payload: response.data})
}

//MEMOIZATION
// export const fetchUser = (id: number) => (dispatch:any) => {
//     _fetchUser(id,dispatch);
// };
//
// const _fetchUser = _.memoize(async (id:number, dispatch:any) => {
//     const response = await jsonPlaceHolder.get('/users/' + id);
//     dispatch({type: 'FETCH_USER', payload: response.data})
// })