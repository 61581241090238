import React from "react";

type Props = {
    items: { title: string, content: string }[]
}
type State = { activeIndex: number | null }


export class Accordion extends React.Component<Props, State> {
    state: State = {activeIndex: null};

    onTitleClick = (index: number) => {
        this.setState({
            activeIndex: index,
        })
    }

    renderItems = () => {
        return this.props.items.map((item, index) => {
            const active = index === this.state.activeIndex ? 'active' : '';
            return (
                <React.Fragment key={item.title}>
                    <div
                        className={"title" + active}
                        onClick={() => this.onTitleClick(index)}
                    >
                        <i className={"dropdown icon"}/>
                        {item.title}
                    </div>
                    <div className={"content" + active}>
                        <p>{item.content}</p>
                    </div>
                </React.Fragment>
            );
        });
    }


    render = () => {
        const {} = this.props;
        const {} = this.state;


        return (
            <div className={"ui styled accordion"}>
                {this.renderItems()}
            </div>
        );
    };
}