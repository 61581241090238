import React from "react";
import {Video} from "types/video";

type Props = {
    video: Video | null
}
type State = {}

export class VideoDetail extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {video} = this.props;
        const {} = this.state;
        if (!video) {
            return <div></div>;
        }

        const videoSrc = `https://www.youtube.com/embed/${video.id.videoId}`
        return (
            <div>
                <div className={"ui embed"}>
                    <iframe title="video player" src={videoSrc}/>
                </div>
                <div className={"ui segment"}>
                    <h4 className={"ui header"}> {video.snippet.title} </h4>
                    <p>{video.snippet.description}</p>
                </div>
            </div>
        );
    }
}