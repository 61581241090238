import React from "react";
import {SearchBar} from "search/SearchBar";
import axios, {AxiosResponse} from "axios";
import {TextResult} from "types/text";
import backend from "api/backend";

type Props = {}
type State = { text: TextResult[], term: string }


export class WikiSearch extends React.Component<Props, State> {
    state: State = {text: [], term: ''};
    timeoutId: any;

    componentDidMount = () => {
        this.fetchData('Friends Sitcom');
    }

    componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
        if (this.state.term != prevState.term) {
            //console.log(this.state.term);
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.fetchData(this.state.term)
            }, 1000);
        }
    }

    onTermChange = (term: string) => {
        this.setState({term: term});
    }

    fetchData = (term: string) => {
        if (term && term != '') {
            backend.post('/wiki/search', {
                action: 'query',
                list: 'search',
                origin: '*',
                format: 'json',
                srsearch: term
            }).then((response: AxiosResponse<any>) => {
                //console.log(response.data);
                this.setState({
                    text: response.data.query.search
                })
                //console.log(this.state.text);
            }).catch((error) => {
                console.log(error);
                this.setState({text: []});
            })
        } else if (term == '') {
            this.setState({text: []});
        }
    }


    render = () => {
        const {} = this.props;
        const {text} = this.state;

        const renderedResults = text.map((result: TextResult) => {
            return (
                <div key={result.pageid} className={"item"}>
                    <div className={"right floated content"}>
                        <a className={"ui button"} href={`https://en.wikipedia.org?curid=${result.pageid}`}>
                            Go
                        </a>
                    </div>
                    <div className={"content"}>
                        <div className={"header"}>
                            {result.title}
                        </div>
                        <span dangerouslySetInnerHTML={{__html: result.snippet}}/>
                    </div>
                </div>
            );
        });

        return (
            <div className={"ui container"}>
                <SearchBar onTermChange={this.onTermChange} onTermSubmit={this.onTermChange}
                           searchLabel={'Wikipedia Search'}
                           defaultTerm={'Friends Sitcom'}/>
                <div className={"ui celled list"}>{renderedResults}</div>
            </div>
        );
    }
}