import React from "react";
import "./VideoItem.css";
import {Video} from "types/video";

type Props = {
    video: Video,
    onVideoSelect: (video: Video) => void
}
type State = {}

export class VideoItem extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {video, onVideoSelect} = this.props;
        const {} = this.state;

        return (
            <div onClick={() => onVideoSelect(video)} className={"video-item item"}>
                <img alt={video.snippet.title} className={"ui image"} src={video.snippet.thumbnails.medium.url}/>
                <div className={"content"}>
                    <div className={"header"}> {video.snippet.title} </div>
                </div>
            </div>
        );
    }
}