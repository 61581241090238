import React from "react";

type Props = {
    avatar: any,
    author: string,
    timeAgo: string,
    comments: string
}
type State = {}

export class CommentDetail extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {avatar, author, timeAgo, comments} = this.props;
        const {} = this.state;

        return (
            <div className="comment">
                <a href="/" className="avatar">
                    <img alt="avatar" src={avatar}/>
                </a>
                <div className="content">
                    <a href="/" className="author">
                        {author}
                    </a>
                    <div className="metadata">
                        <span className="date">{timeAgo}</span>
                    </div>
                    <div className="text">{comments}</div>
                </div>
            </div>
        );
    }
}