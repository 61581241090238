import React, {FormEvent} from "react";

type Props = {
    onTermSubmit: (term: string) => void,
    onTermChange?: (term: string) => void,
    searchLabel: string,
    defaultTerm: string
}
type State = {
    term: string
}

export class SearchBar extends React.Component<Props, State> {
    state: State = {term: this.props.defaultTerm};

    onInputChange = (event: { target: { value: any; }; }) => {
        if (this.props.onTermChange)
            this.props.onTermChange(event.target.value);
        this.setState({term: event.target.value});
    };

    onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onTermSubmit(this.state.term);
    };

    render = () => {
        const {searchLabel} = this.props;
        const {term} = this.state;

        return (
            <div className={"search-bar ui segment"}>
                <form onSubmit={this.onFormSubmit} className={"ui form"}>
                    <div className={"field"}>
                        <label>{searchLabel}</label>
                        <input type={"text"} value={term} onChange={this.onInputChange}/>
                    </div>
                </form>
            </div>
        );
    }
}