import axios from 'axios';

const KEY='AIzaSyCBzXXcTsrGYbzu_dMDAkin4TidRH0ofFE';

export default  axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
    params:{
        part:'snippet',
        type: 'video',
        maxResults: 5,
        key: KEY
    }
});

