import React from "react";

type Props = {
    message?: string
}
type State = {}

export class Spinner extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {message} = this.props;
        const {} = this.state;

        return (
            <div className="ui active dimmer">
                <div className="ui bog text lader">
                    {message || "Loading..."}
                </div>
            </div>
        );
    }
}