import React from "react";
import {SeasonDisplay} from "seasons/SeasonDisplay";
import {Spinner} from "seasons/Spinner";

type Props = {}
type State = {
    lat: number | null,
    errorMessage: string
}

export class Seasons extends React.Component<Props, State> {
    state: State = {lat: null, errorMessage: ''};

    componentDidMount = () => {
        window.navigator.geolocation.getCurrentPosition(
            (position) => this.setState({lat: position.coords.latitude}),
            //console.log(position);
            (err) => this.setState({errorMessage: err.message})
            //console.log(err);
        );
    }

    renderContent = () => {
        if (this.state.errorMessage && !this.state.lat) {
            return <div>Error:{this.state.errorMessage}</div>
        }

        if (!this.state.errorMessage && this.state.lat) {
            //return<div>Latitude:{this.state.lat}</div>
            return <SeasonDisplay lat={this.state.lat}/>
        }

        return <Spinner message="Please accept location request"/>;
    }

    render = () => {
        return (
            <div className="ui container">
                {this.renderContent()}
            </div>
        );
    }

}