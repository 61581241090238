import React from "react";
import {Image} from "types/image";
import {SearchBar} from "search/SearchBar";
import {ImageList} from "image/ImageList";
import backend from "api/backend";
import {AxiosResponse} from "axios";

type Props = {}
type State = {
    images: Image[]
}

export class ImageApp extends React.Component<Props, State> {
    state: State = {images: []};

    onSearchSubmit = (term: string) => {
        console.log(term);
        backend.post('/unsplash/photos', {
            query: term,
            perPage: 10
        }).then((response: AxiosResponse<any>) => {
            this.setState({images: response.data.results});
            console.log(response);
        }).catch((error) => {
            console.log(error);
            this.setState({images: []})
        });
        //console.log(response.data.results);
    }


    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div className="ui container" style={{marginTop: '10px'}}>
                <SearchBar defaultTerm={""} searchLabel={"Image Search"} onTermSubmit={this.onSearchSubmit}/>
                <ImageList images={this.state.images}/>
            </div>
        );
    }
}