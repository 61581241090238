import React from "react";

type Props = {
    path: string,
    children: any
}
type State = {
    currentPath:string
}

export class Route extends React.Component<Props, State> {
    state: State = {currentPath:window.location.pathname};

    componentDidMount = () => {
        const onLocationChange = () => {
            this.setState({currentPath:window.location.pathname});
        }
        window.addEventListener('popstate', onLocationChange);
        return () => {
            window.removeEventListener('popstate', onLocationChange);
        }
    }

    render = () => {
        const {path, children} = this.props;
        const {currentPath} = this.state;

        return currentPath === path ? children : null;
    }
}