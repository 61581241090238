import {Song} from "types/songs";

export const songsReducer = () => {
    return [
        {
            title: 'Something just like this', duration: '5:02'
        },
        {
            title: 'Club can\'t handle me', duration: '4:30'
        },
        {
            title: 'Closer', duration: '3:42'
        },
        {
            title: 'It ain\'t me', duration: '4:43'
        }
    ];
};

export const selectedSongReducer = (selectedSong = null, action: { type: string, payload: Song }) => {
    if (action.type === 'SONG_SELECTED') {
        return action.payload;
    }
    return selectedSong;
}