import React from "react";
import {DropDown} from "widget/DropDown";
import {Convert} from "widget/Convert";

type Props = {}
type State = { language: { label: string, value: string }, text: string }

const options = [
    {
        label: 'Afrikaans',
        value: 'af'
    },
    {
        label: 'Arabic',
        value: 'ar'
    },
    {
        label: 'Hindi',
        value: 'hi'
    },
    {
        label: 'Dutch',
        value: 'nl'
    },
    {
        label: 'French',
        value: 'fr'
    },
    {
        label: 'German',
        value: 'de'
    }
];

export class Translator extends React.Component<Props, State> {
    state: State = {language: options[0], text: ''};

    updateText = (text: string) => {
        this.setState({text: text});
    }


    updateLanguage = (newValue: { label: string, value: string }) => {
        this.setState({language: newValue});
    }


    render = () => {
        const {} = this.props;
        const {language, text} = this.state;

        return (
            <div>
                <div className={"ui form"}>
                    <div className={"field"}>
                        <label>Enter text</label>
                        <input value={text} onChange={(e) => this.updateText(e.target.value)}/>
                    </div>
                    <DropDown options={options} label={'Choose language'} selected={language}
                              onSelectedChange={this.updateLanguage}/>
                    <hr/>
                    <h3 className={"ui header"}>Output</h3>
                    <Convert language={language} text={text}/>
                </div>
            </div>
        );
    }
}