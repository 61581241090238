import React from "react";

type Props = {
    href: string,
    className: string
}
type State = {}

export class Link extends React.Component<Props, State> {
    state: State = {};

    onClick = (event: any) => {
        if (event.metaKey || event.ctrlKey) {
            return;
        }
        event.preventDefault();
        window.history.pushState({}, '', this.props.href);
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
    }

    render = () => {
        const {href, className, children} = this.props;
        const {} = this.state;

        return (
            <a onClick={this.onClick} className={className} href={href}>{children}</a>
        );
    }
}