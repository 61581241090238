import React from "react";
import faker from "faker";
import {ApprovalCard} from "comment-card/ApprovalCard";
import {CommentDetail} from "comment-card/CommentDetail";

type Props = {}
type State = {}

export class CommentCard extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div className="ui container comments">
                <ApprovalCard>
                    <div>
                        <h4>WARNING!</h4>
                        Are you sure you want to proceed?
                    </div>
                </ApprovalCard>
                <ApprovalCard>
                    <CommentDetail author="Sam" timeAgo="Today at 6:00PM" comments="Nice blog post!"
                                   avatar={faker.image.avatar()}/>
                </ApprovalCard>
                <ApprovalCard>
                    <CommentDetail author="Alex" timeAgo="Today at 2:00AM" comments="Interesting post!"
                                   avatar={faker.image.avatar()}/>
                </ApprovalCard>
                <ApprovalCard>
                    <CommentDetail author="Jane" timeAgo="Yesterday at 5:00PM" comments="Highly creative!"
                                   avatar={faker.image.avatar()}/>
                </ApprovalCard>
            </div>
        );
    }
}