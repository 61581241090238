import React from "react";
import {SearchBar} from "search/SearchBar";
import youtube from "api/youtube";
import {VideoList} from "videos/VideoList";
import {Video} from "types/video";
import {VideoDetail} from "videos/VideoDetail";
import {AxiosResponse} from "axios";

type Props = {}
type State = {
    videos: Video[],
    selectedVideo: Video | null
}

export class VideoApp extends React.Component<Props, State> {
    state: State = {videos: [], selectedVideo: null};

    componentDidMount = () => {
        this.onTermSubmit('glaciers');
    };

    onTermSubmit = (term: string) => {
        //console.log(term);
        youtube.get('/search', {
            params: {
                q: term
            }
        }).then((response: AxiosResponse<any>) => {
            this.setState({
                videos: response.data.items,
                selectedVideo: response.data.items[0]
            });
        }).catch((error) => {
            console.error(error);
            this.setState({
                videos: [],
                selectedVideo: null
            });
        });

        //console.log(response.data.items);
    };

    onVideoSelect = (video: Video) => {
        this.setState({selectedVideo: video})
    }

    render = () => {
        const {} = this.props;
        const {selectedVideo, videos} = this.state;

        return (
            <div className={"ui container"}>
                <SearchBar defaultTerm={"glaciers"} searchLabel={"Video Search"} onTermSubmit={this.onTermSubmit}/>
                <div className={"ui grid"}>
                    <div className={"ui row"}>
                        <div className={"ten wide column"}>
                            <VideoDetail video={selectedVideo}/>
                        </div>
                        <div className={"six wide column"}>
                            <VideoList onVideoSelect={this.onVideoSelect} videos={videos}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}