import React, {MouseEventHandler} from "react";

type Props = {
    options: { label: string, value: string }[],
    label: string,
    selected: { label: string, value: string },
    onSelectedChange: (selectedValue: { label: string; value: string }) => void
}
type State = {
    open: boolean
}

export class DropDown extends React.Component<Props, State> {
    state: State = {open: false};

    componentDidMount = () => {
        document.body.addEventListener('click', () => {
            if (this.state.open) {
                //console.log('BODY CLICK');
                this.setState({open: false});
            }
        });
    }

    renderOptions = () => {
        return this.props.options.map((option) => {
            if (option.value === this.props.selected.value) {
                return null;
            }
            return (
                <div key={option.value}
                     onClick={() => {
                         //console.log('Item clicked');
                         this.props.onSelectedChange(option)
                     }}
                     className={"item"}>
                    {option.label}
                </div>
            );
        });
    }

    render = () => {
        const {label, selected} = this.props;
        const {open} = this.state;

        return (
            <div className={"ui form"}>
                <div className={"field"}>
                    <label className={"label"}>{label}</label>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            //console.log('Dropdown clicked');
                            this.setState({open: !open})
                        }}
                        className={`ui selection dropdown ${open ? 'visible active' : ''}`}>
                        <i className={"dropdown icon"}/>
                        <div className={"text"}>{selected.label}</div>
                        <div className={`menu ${open ? 'visible transition' : ''}`}>
                            {this.renderOptions()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}