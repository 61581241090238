import React from "react";
import {Image} from "types/image";
import {ImageCard} from "image/ImageCard";
import './ImageList.css';

type Props = {
    images: Image[]
}
type State = {}

export class ImageList extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {images} = this.props;
        const {} = this.state;

        const renderedImages = images.map((image) => {
            return <ImageCard key={image.id} image={image}/>
        });
        return (
            <div className="image-list">
                {renderedImages}
            </div>
        );
    }
}