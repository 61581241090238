import {Song} from "types/songs";

export const selectSong = (song: Song) => {
    return {
        type: 'SONG_SELECTED',
        payload: song
    };
};


