import React from "react";

type Props = {
    children: any
}
type State = {}


export class ApprovalCard extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {children} = this.props;
        const {} = this.state;

        return (
            <div className="ui card">
                <div className="content">{children}</div>
                <div className="extra content">
                    <div className="ui two buttons">
                        <div className="ui basic green button">Approve</div>
                        <div className="ui basic red button">Reject</div>
                    </div>
                </div>
            </div>
        );
    }
}