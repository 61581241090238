import React from "react";
import {connect} from "react-redux";
import {fetchPostsAndUsers} from "actions/blog";
import {Store} from "types/store";
import {User} from "types/user";

type Props = {
    userId?: number,
    user?: User
}
type State = {}

class UserHeader extends React.Component<Props, State> {
    state: State = {};


    render = () => {
        const {user} = this.props;
        const {} = this.state;

        //console.log(this.props.users);
        //console.log(user);
        if (!user) {
            return null;
        }
        return (
            <div className={"header"}>
                {user.name}
            </div>
        );
    }
}

const mapStateToProps = (state: Store, ownProps: Props) => {
    //console.log(state);
    return {user: state.users.find((user) => user.id === ownProps.userId)}
}

export default connect(mapStateToProps)(UserHeader);