import React from "react";
import './SeasonDisplay.css';

type Props = {
    lat: number
}
type State = {}

export class SeasonDisplay extends React.Component<Props, State> {
    state: State = {};

    seasonConfig = {
        summer: {
            text: 'Let\'s hit the beach',
            //or text: "Let's hit the beach'
            iconName: 'sun'
        },
        winter: {
            text: 'Burr, it is chilly',
            iconName: 'snowflake'
        }
    }

    getSeason = (lat: number, month: number) => {
        if (month > 2 && month < 9) {
            return lat > 0 ? 'summer' : 'winter';
        } else {
            return lat > 0 ? 'summer' : 'winter';
        }
    }

    render = () => {
        const {lat} = this.props;
        const {} = this.state;

        const season = this.getSeason(lat, new Date().getMonth());
        const {text, iconName} = this.seasonConfig[season];

        return (
            <div className={`season-display ${season}`}>
                <i className={`huge ${iconName} icon`}/>
                <h1>{text}</h1>
                <i className={`huge ${iconName} icon`}/>
            </div>
        );
    }
}