import React from "react";
import {Image} from "types/image";

type Props = {
    image: Image
}
type State = {
    spans: number
}

export class ImageCard extends React.Component<Props, State> {
    state: State = {spans: 0};
    imageRef: React.RefObject<HTMLImageElement>;

    constructor(props: Props) {
        super(props);
        this.imageRef = React.createRef();
    };

    componentDidMount = () => {
        //console.log(this.imageRef.current.clientHeight);
        this.imageRef.current?.addEventListener('load', this.setSpans);
    }

    setSpans = () => {
        const height = (this.imageRef.current?.clientHeight) || 0;
        const spans = Math.ceil(height / 10);
        this.setState({spans: spans});
    };


    render = () => {
        const {image} = this.props;
        const {} = this.state;

        const {description, urls} = image;
        const {regular}=urls;
        return (
            <div style={{gridRowEnd: `span ${this.state.spans}`}}>
                <img
                    ref={this.imageRef}
                    alt={description}
                    src={regular}
                />
            </div>
        );
    }
}