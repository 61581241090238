import React from "react";
import axios, {AxiosResponse} from "axios";

type Props = {
    language: { label: string, value: string },
    text: string
}
type State = {
    translatedText: string,
}

export class Convert extends React.Component<Props, State> {
    state: State = {translatedText: ''};
    timeoutId: any;

    componentDidMount = () => {
        this.translate(this.props.text);
    }

    translate = (text: string) => {
        if (text && text != '') {
            axios.post('https://translation.googleapis.com/language/translate/v2', {}, {
                params: {
                    q: text,
                    target: this.props.language.value,
                    key: 'AIzaSyCHUCmpR7cT_yDFHC98CZJy2LTms-IwDlM'
                }
            }).then((response: AxiosResponse<any>) => {
                this.setState({
                    translatedText: response.data.data.translations[0].translatedText
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    translatedText: ''
                })
            });
        } else if (text == '') {
            this.setState({translatedText: ''})
        }
    }

    componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
        if (prevProps.text !== this.props.text || prevProps.language !== this.props.language) {
            console.log("New language or text");
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.translate(this.props.text)
            }, 500);
        }
    }

    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div>
                <h1 className={"ui header"}>
                    {this.state.translatedText}
                </h1>
            </div>
        );
    }
}