import React from "react";
import {connect} from "react-redux";
import {Store} from "types/store";
import {Song} from "types/songs";
import {selectSong} from "actions/songs";

type Props = { songs: Song[], selectSong: (song: Song) => {} }
type State = {}

class SongsList extends React.Component<Props, State> {
    state: State = {};

    renderList = () => {
        return this.props.songs.map((song: Song) => {
            return (
                <div className={"item"} key={song.title}>
                    <div className={"right floated content"}>
                        <button
                            className={"ui button primary"}
                            onClick={() => this.props.selectSong(song)}
                        >
                            Select
                        </button>
                    </div>
                    <div className={"content"}>{song.title}</div>
                </div>
            );
        });
    }

    render = () => {
        const {} = this.props;
        const {} = this.state;

        //console.log(this.props);

        return (
            <div className={"ui divided list"}>
                {this.renderList()}
            </div>
        );
    }
}

const mapStateToProps = (state: Store) => {
    console.log(state);
    return {songs: state.songs};
}

export default connect(mapStateToProps, {
    selectSong: selectSong
})(SongsList);