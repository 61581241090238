import React from "react";
import {Accordion} from "widget/Accordion";
import {WikiSearch} from "widget/WikiSearch";
import {DropDown} from "widget/DropDown";
import {Translator} from "widget/Translator";
import {Route} from "navigation/Route";


type Props = {}
type State = { selectedValue: { label: string, value: string } }

const items = [
    {
        title: 'What’s the name of Joey’s penguin?',
        content: 'Huggsy'
    },
    {
        title: 'What kind of uniform does Joey wear to Monica and Chandler’s wedding?',
        content: 'Soldier'
    },
    {
        title: 'What is the name of Phoebe’s alter-ego?',
        content: 'Regina Phalange'
    }
];

const options = [
    {
        label: 'Joey Tribbiani',
        value: 'joey'
    },
    {
        label: 'Phoebe Buffay',
        value: 'phoebe'
    },
    {
        label: 'Chandler Bing',
        value: 'chandler'
    },
    {
        label: 'Monica Geller',
        value: 'mon'
    },
    {
        label: 'Ross Geller',
        value: 'ross'
    },
    {
        label: 'Rachel Green',
        value: 'rach'
    }
];

export class WidgetApp extends React.Component<Props, State> {
    state: State = {selectedValue: options[0]};

    updateState = (newValue: { label: string, value: string }) => {
        this.setState({selectedValue: newValue});
    }


    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div>
                <Route path={"/accordion"}>
                    <Accordion items={items}/>
                </Route>
                <Route path={"/list"}>
                    <WikiSearch/>
                </Route>
                <Route path={"/dropdown"}>
                    <DropDown selected={this.state.selectedValue}
                              onSelectedChange={this.updateState}
                              options={options}
                              label={"Choose your fav"}/>
                </Route>
                <Route path={"/translate"}>
                    <Translator/>
                </Route>
            </div>
        );
    }
}