import {combineReducers} from "redux";
import {selectedSongReducer, songsReducer} from "reducers/songs";
import postsReducer from "reducers/blog/PostReducer";
import userReducer from "reducers/blog/UserReducer"


export default combineReducers({
    songs: songsReducer,
    selectedSong: selectedSongReducer,
    posts: postsReducer,
    users: userReducer
});