import React from "react";
import {Link} from "navigation/Link";

type Props = {}
type State = {}

export class Header extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {} = this.props;
        const {} = this.state;

        return (
            <div className={"ui secondary pointing menu"}>
                <Link href={"/"} className={"item"}>
                    Weather
                </Link>
                <Link href={"/comment"} className={"item"}>
                    Comments
                </Link>
                <Link href={"/images"} className={"item"}>
                    Images
                </Link>
                <Link href={"/video"} className={"item"}>
                    Video
                </Link>
                <Link href={"/accordion"} className={"item"}>
                    Accordion
                </Link>
                <Link href={"/list"} className={"item"}>
                    Search
                </Link>
                <Link href={"/dropdown"} className={"item"}>
                    Dropdown
                </Link>
                <Link href={"/translate"} className={"item"}>
                    Translate
                </Link>
                <Link href={"/songs"} className={"item"}>
                    Songs
                </Link>
                <Link href={"/blog"} className={"item"}>
                    Posts
                </Link>
            </div>
        );
    }
}