import React from "react";
import {Store} from "types/store";
import {connect} from "react-redux";
import {Song} from "types/songs";

type Props = {
    song?:Song
}
type State = {

}

class SongDetail extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {song} = this.props;
        const {} = this.state;
        console.log(this.props);
        if(!song){
            return (
                <div>Select a song</div>
            );
        }

        return (
            <div>
                <h3>Details for:</h3>
                <p>
                    Title: {song.title}
                    <br/>
                    Duration: {song.duration}
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state: Store) => {
    //console.log(state);
    return {song: state.selectedSong};
}

export default connect(mapStateToProps)(SongDetail);