import React from "react";
import {VideoItem} from "videos/VideoItem";
import {Video} from "types/video";

type Props = {
    videos: Video[],
    onVideoSelect: (video: Video) => void
}
type State = {}

export class VideoList extends React.Component<Props, State> {
    state: State = {};

    render = () => {
        const {videos, onVideoSelect} = this.props;
        const {} = this.state;

        const renderedVideos = videos.map((video) => {
            return <VideoItem key={video.id.videoId} onVideoSelect={onVideoSelect} video={video}/>;

        });

        return (
            <div className={"ui relaxed divided list"}>
                {renderedVideos}
            </div>
        );
    }
}